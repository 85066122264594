import React, { Suspense, lazy } from "react";
import logo from "./logo.svg";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Spinner from "./components/Spinner/Spinner";
import VideoGallery from "./screen/VideoGallery/VideoGallery";
const HomePage = lazy(() => import("./screen/HomePage/HomePage"));
const AboutUs = lazy(() => import("./screen/AboutUs/AboutUs"));
const ContactUs = lazy(() => import("./screen/ContactUs/ContactUs"));
const Gallery = lazy(() => import("./screen/Gallery/Gallery"));
const Booking = lazy(() => import("./screen/Booking/Booking"));
const Error = lazy(() => import("./screen/Error/Error"));
const Policy = lazy(() => import("./screen/Policy/Policy"));
const Service = lazy(() => import("./screen/Service/Service"));

function App() {
  return (
    <Router>
      {/* <PromotionIcon
        logoPath={Promotion}
        link="https://divinenailbooking.as.me/schedule.php?fbclid=IwAR3eak9t1wdaz3uyNZ0rzqAd84ypKWqVV6vLzZRb5tqSNaI2RZZftMS3GjY"
      /> */}
      <Suspense fallback={<Spinner/>}>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="about-us" element={<AboutUs />}></Route>
          <Route path="contact-us" element={<ContactUs />}></Route>
          <Route path="gallery" element={<Gallery />}></Route>
          <Route path="gallery/:page" element={<Gallery />}></Route>
          <Route path="booking" element={<Booking />}></Route>
          <Route path="video" element={<VideoGallery />}></Route>
          <Route path="policy" element={<Policy />}></Route>
          <Route path="service" element={<Service />}></Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
